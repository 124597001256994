body{
  max-width: 100vw;
  width: 100vw;
}

a{
  color: black !important; 
 }

#credits{
  float:right;
  position: relative;
}

#credits a{
  color: white !important;
}

#container{
  height: 100vh;
  overflow-y: scroll;
  padding-top: 25px;
}


.card{
  min-width: 250px !important;
  margin-bottom: 5px;
  color:black;
  
}

p{
  min-width: 100%;
  width: 0;
}

.fixed{
  padding-right: 10px;
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}


table{
  color: white !important;
  width: 100px;
  margin: 10px;
  padding-left: 0;
}

.table-responsive{
  padding:0px !important;
}

table { border-collapse: collapse; }
th {
  border-right: solid 2px #FFFFFF !important; 
}
thead{
  text-align: center;
}
td {
  border-right: solid 2px #FFFFFF !important; 
  justify-content: center !important;
}

.secretArea{
  background-color: black;
  border-radius: 15px;
  width: 80vw !important;
  margin-left: 10vw;
  margin-bottom: 20px;
}

.button-container{
  display: flex;
  width: fit-content;
  float: right;
}